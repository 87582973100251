<template>
<div class="scan-popup">
    <div class="content">
        <div class="cross" @click="closePrescriptionPopup()">X</div>
        
        <div id="scan_content" v-if="prescription">

                <b>Nom :</b> {{prescription.name}}
                <br />
				<div v-if="prescription.file!=null && prescription.file!=''">
                	<b>Fichier :</b> <a :href="prescription.file">Télécharger</a>
				</div>
				<div v-else>
					
					<b>Oeil gauche :</b><br /><br />
					Sphère : {{prescription.left_sphere}}<br />
					Cylindre : {{prescription.left_cylinder}}<br />
					Addition : {{prescription.left_addition}}<br />
					Axe : {{prescription.left_axis}}<br />
					Ecart pupillaire : {{prescription.left_pupillary_distance}}<br />
					Prisme : {{prescription.left_prism}}<br />

					<br />

					<b>Oeil droit :</b><br /><br />
					Sphère : {{prescription.right_sphere}}<br />
					Cylindre : {{prescription.right_cylinder}}<br />
					Addition : {{prescription.right_addition}}<br />
					Axe : {{prescription.right_axis}}<br />
					Ecart pupillaire : {{prescription.right_pupillary_distance}}<br />
					Prisme : {{prescription.right_prism}}<br />

					<br />

					Ecart pupillaire total : {{prescription.pupillary_distance}}

				</div>
        </div>
    </div>
</div>
</template>

<script>

import {APIRequest} from '@simb2s/senseye-sdk'
import { myVar } from './coordinates.js'
import * as BABYLON from 'babylonjs';
import emitter from 'tiny-emitter/instance'

export default {
  name: 'PrescriptionPopup',
  components: {
    
  },
  props:['prescription_id'],
  data() {
    return {
      prescription:null,
    }
  },
  methods:{
    
    closePrescriptionPopup()
    {
        emitter.emit('hide-popup-prescription')
    },
	
  },
  async mounted()
  {
   
    this.prescription=await APIRequest.detail('prescriptions',this.prescription_id)


  }
}
</script>
<style scoped>
.scan-popup .content .cross
{
    box-shadow: 0px 5px 5px rgba(0,0,0,0.5);
    font-size:20px;
    position:absolute;
    right:0;
    z-index:1000;
    text-align:center;
    cursor:pointer;
    padding:6px 15px;
    top:0px;
    background:#fff;
    border-radius:50%;
}
.scan-popup .content
{
    position:absolute;
    width:70%;
    top:5%;
    left:5%;
    height:70%;
    overflow-y:auto;
    background:#fff;
    padding:40px;
}
.scan-popup
{
    position:fixed;
    top:0;
    left:0;
    z-index:1000;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.8);
}
</style>