
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
    <b-card title="Informations générales">
     <!-- form -->
      <validation-observer ref="loginValidation">
        <b-form
          class="auth-user-form mt-2"
          @submit.prevent
        >
    
      <b-row>
         <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
          <b-form-group
            label="Nom"
            label-for="basicInput"
          >
          <validation-provider
                  #default="{ errors }"
                  name="Nom"
                  rules="required"
                >
            <b-form-input
             required
              id="basicInput"
              v-model="form.name"
              :plaintext="readOnly"
              placeholder="Nom"
             
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
          <b-form-group
            label="Prénom"
            label-for="basicInput"
          >
          <validation-provider
                  #default="{ errors }"
                  name="Prénom"
                  rules="required"
                >
            <b-form-input
             required
              id="basicInput"
              v-model="form.surname"
              :plaintext="readOnly"
              placeholder="Prénom"
             
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
        </b-col>

        
      </b-row>
      <b-row>

        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
          <b-form-group
            label="Téléphone"
            label-for="basicInput"
          >
         <validation-provider
                  #default="{ errors }"
                  name="Téléphone"
                  rules="required"
                >
            <b-form-input
             required
              id="basicInput"
              v-model="form.phone"
              :plaintext="readOnly"
              placeholder="Téléphone"
           
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
        </b-col>
        
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
        
          <b-form-group
            label="Email"
            label-for="basicInput"
          >
          <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
            <b-form-input
             
              id="basicInput"
              type="email"
              v-model="form.email"
              :state="errors.length > 0 ? false:null"
              :plaintext="readOnly"
              placeholder="Email"
             
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group> 
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="readOnly==false && edition == false"
          md="6"
          xl="4"
          class="mb-1"
        >
          <b-form-group 
            label="Mot de passe"
            label-for="basicInput"
          >
          <validation-provider
                  #default="{ errors }"
                  name="Mot de passe"
                  rules="required"
                >
            <b-form-input
             required
              id="basicInput"
              type="password"
              v-model="form.password"
              :plaintext="readOnly"
              placeholder="Password"
             
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
        </b-col>

      </b-row>
      <div v-if="readOnly==false">
       <b-button v-if="edition==false"
        @click="createUser"
        >
        Enregistrer
        </b-button>

        <b-button v-else :disabled="invalid"
        @click="updateUser"
        >
        Enregistrer
        </b-button>
      </div>
      </b-form>
          </validation-observer>
    </b-card>
  </div>
</b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BButton,BFormInput, BOverlay, BRow, BCol, BFormGroup,BFormFile, BCard, BFormCheckbox, BFormTextarea, BForm} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import store from '@/store/index'
import {APIRequest, Auth} from '@simb2s/senseye-sdk'

export default {
  name: 'UserForm',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BForm
  },
  data() {
      return {
          currentBase64:null,
          file: null,
          edition:false,
          loading:true,
          validation: true,
          form:{
            name:"",
            surname: "",
            username:"",
            phone:"",
            email:"",
            password: ""
          },
          required
      }
  },
  props: ['user', 'readOnly'],
  methods:{
      async createUser()
      {
        this.$refs.loginValidation.validate().then(async success => {
        if (success) {
          this.loading=true
           try{
              // $check_surname = await Auth.get_user(this.form.surname)
              let checkuser = await Auth.get_user(this.form.email)
              
              if(!checkuser){
           
                this.form.username=this.form.email
                let $register = await Auth.register(this.form)
                if($register && $register.status == 200){
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'L\'utilisateur a bien été créé !',
                          icon: 'CheckIcon',
                          variant: 'success',
                      },
                  })
                  this.$router.push({name:'users'})
                }
              }
              else{
                this.loading=false
                
              }
           }
           catch(error)
           {
             this.loading=false
             
           }

        }
        })
      },
      async updateUser()
      {
        this.$refs.loginValidation.validate().then(async success => {
        if (success) {
          this.loading=true
          let datas = {}
          datas.name = this.form.name
          datas.surname = this.form.surname
          datas.email = this.form.email
          datas.username = this.form.email
          datas.phone = this.form.phone
          
          try{
               
              await Auth.update_account(this.$route.params.id, datas)
            
              this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: 'L\'utilisateur a bien été modifié !',
                      icon: 'CheckIcon',
                      variant: 'success',
                  },
              })
              this.$router.push({name:'users'})
              
          } catch (error) {
            
            this.loading=false
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Une erreur est survenue.',
                    icon: 'AlertCircleIcon',
                    variant: 'warning',
                },
            })
            
          }
        }
        })
        //   this.validation = false
      }
     
      
  },
  async mounted(){
      
    if(this.user!=null)
    {
      this.form = this.user
      this.edition=true
    }
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>