<template>
<div class="scan-popup">
    <div class="content">
        <div class="cross" @click="closeScanPopup()">X</div>
        <div class="canvas-content">
            <canvas id="renderCanvas"></canvas> <!-- touch-action="none" for best results from PEP -->
            <canvas id="renderCanvas2"></canvas>
        </div>
        <div id="scan_content" v-if="scan">

                <b>Nom :</b> {{scan.name}}
                <br />
                <b>Date :</b> {{scan.date}}
                <br />
                <b>Mesures :</b> {{scan.measurements}}<br />
                <b>Mesh :</b> {{scan.coordinates}}
        </div>
    </div>
</div>
</template>

<script>

import {APIRequest} from '@simb2s/senseye-sdk'
import { myVar } from './coordinates.js'
import * as BABYLON from 'babylonjs';
import emitter from 'tiny-emitter/instance'

export default {
  name: 'ScanDetail',
  components: {
    
  },
  props:['scan_id'],
  data() {
    return {
      scan_id:null,
      scan:null,
      error:'',
	  scene:null,
	  scene2:null,
	  engine:null,
	  engine2:null,
	  canvas:null,
	  canvas2:null
    }
  },
  methods:{
    replaceAll(recherche, remplacement, chaineAModifier)
    {
    	return chaineAModifier.split(recherche).join(remplacement);
    },

    closeScanPopup()
    {
        emitter.emit('hide-popup-scan')
    },
	async createScene2  () {
		this.scene2 = new BABYLON.Scene(this.engine2);
		this.scene2.clearColor = BABYLON.Color3.White();
		var light = new BABYLON.DirectionalLight("hemi", new BABYLON.Vector3(0, 0, 1), this.scene2);
		
		var camera = new BABYLON.ArcRotateCamera("camera1",  0, 0, 50, new BABYLON.Vector3(0, 0, 0), this.scene2);
		camera.wheelPrecision = 100;
		camera.setPosition(new BABYLON.Vector3(1, 0, 1));
		camera.attachControl(this.canvas2, true);
		var customMesh2 = new BABYLON.Mesh("custom2", this.scene2);
		
		var coordinates=this.scan.coordinates;

		coordinates=this.replaceAll('"dataList":','',coordinates);
		coordinates=this.replaceAll('"x":','',coordinates);
		coordinates=this.replaceAll('"y":','',coordinates);
		coordinates=this.replaceAll('"z":','',coordinates);
		coordinates=this.replaceAll('{','',coordinates);
		coordinates=this.replaceAll('}','',coordinates);
		
		coordinates=JSON.parse(coordinates);
	
		//Set arrays for positions and indices
		var positions = [];
		var positions=coordinates;
		var indices=[];
		for(var i=0;i<coordinates.length;i++)
		{
			indices.push(i);
		}
		
		//Create a vertexData object
		var vertexData = new BABYLON.VertexData();

		//Assign positions and indices to vertexData
		vertexData.positions = positions;
		vertexData.indices = indices;	

		//Apply vertexData to custom mesh
		vertexData.applyToMesh(customMesh2);
		
		
		/******Display custom mesh in wireframe view to show its creation****************/
		var mat = new BABYLON.StandardMaterial("mat", this.scene2);
		mat.diffuseColor = BABYLON.Color3.Red();
		mat.wireframe = true;
		customMesh2.material = mat;
		
		customMesh2.material.pointsCloud = true;
		customMesh2.material.pointSize = 1;
		
		customMesh2.scaling.x = 3;
		customMesh2.scaling.y = 3;
		customMesh2.scaling.z = 3;
		
		customMesh2.position = new BABYLON.Vector3(0,0,0);
		/*******************************************************************************/
		customMesh2.rotation = new BABYLON.Vector3(0, -0.8, 0);

		return this.scene2;
	},
	async createScene() {
		this.scene = new BABYLON.Scene(this.engine);
		this.scene.clearColor = BABYLON.Color3.White();
		var light = new BABYLON.DirectionalLight("hemi", new BABYLON.Vector3(0, 0, 1), this.scene);
		
		var camera = new BABYLON.ArcRotateCamera("camera1",  0, 0, 50, new BABYLON.Vector3(0, 0, 0), this.scene);
		camera.wheelPrecision = 100;
		camera.setPosition(new BABYLON.Vector3(1, 0, 1));
		camera.attachControl(this.canvas, true);
		var customMesh = new BABYLON.Mesh("custom", this.scene);
		var coordinates=this.scan.coordinates;
		coordinates=this.replaceAll('"dataList":','',coordinates);
		coordinates=this.replaceAll('"x":','',coordinates);
		coordinates=this.replaceAll('"y":','',coordinates);
		coordinates=this.replaceAll('"z":','',coordinates);
		coordinates=this.replaceAll('{','',coordinates);
		coordinates=this.replaceAll('}','',coordinates);
		coordinates=JSON.parse(coordinates);
		var positions = myVar;
		var indices=[];
		for(var i=0;i<coordinates.length;i++)
		{
			indices.push(i);
		}
		//Create a vertexData object
		var vertexData = new BABYLON.VertexData();
		//Assign positions and indices to vertexData
		vertexData.positions = positions;
		vertexData.indices = indices;	
		vertexData.applyToMesh(customMesh);
		var mat = new BABYLON.StandardMaterial("mat", this.scene);
		mat.diffuseColor = BABYLON.Color3.Red();
		mat.wireframe = true;
		customMesh.material = mat;
		customMesh.material.pointsCloud = true;
		customMesh.material.pointSize = 1;
		customMesh.scaling.x = 3;
		customMesh.scaling.y = 3;
		customMesh.scaling.z = 3;
		customMesh.position = new BABYLON.Vector3(0,0,0);
		customMesh.rotation = new BABYLON.Vector3(0, 0.8, 0);
		return this.scene;
	},
  },
  async mounted()
  {
   
    this.scan=await APIRequest.detail('scans',this.scan_id)
	this.canvas = document.getElementById("renderCanvas"); // Get the canvas element
	this.engine = new BABYLON.Engine(this.canvas, true); // Generate the BABYLON 3D engine
	this.scene = await this.createScene(); //Call the createScene function

	const _this = this;

	// Register a render loop to repeatedly render the scene
	this.engine.runRenderLoop(function () {
			_this.scene.render();
	});

	// Watch for browser/canvas resize events
	window.addEventListener("resize", function () {
			_this.engine.resize();
	});
	
	this.canvas2 = document.getElementById("renderCanvas2"); // Get the canvas element
	this.engine2 = new BABYLON.Engine(this.canvas2, true); // Generate the BABYLON 3D engine

	this.scene2 = await this.createScene2(); //Call the createScene function

	this.engine2.runRenderLoop(function () {
			_this.scene2.render();
	});

	// Watch for browser/canvas resize events
	window.addEventListener("resize", function () {
			_this.engine2.resize();
	});

  }
}
</script>
<style scoped>
.scan-popup .content .cross
{
    box-shadow: 0px 5px 5px rgba(0,0,0,0.5);
    font-size:20px;
    position:absolute;
    right:0;
    z-index:1000;
    text-align:center;
    cursor:pointer;
    padding:6px 15px;
    top:0px;
    background:#fff;
    border-radius:50%;
}
.scan-popup .content
{
    position:absolute;
    width:70%;
    top:5%;
    left:5%;
    height:70%;
    overflow-y:auto;
    background:#fff;
    padding:40px;
}
.scan-popup
{
    position:fixed;
    top:0;
    left:0;
    z-index:1000;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.8);
}
</style>